<template>
	<el-dialog :visible.sync="show" width="45%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div v-if="!userShowList" style="padding-right: 30px;">
			<div slot="title" class="header-title">
				<el-row :gutter="5">
					<el-col :span="24">
						<span class="title-name" style="font-size: 20px;">{{ info.title }}</span>
					</el-col>
				</el-row>
			</div>
			<div style="width: 100%;height: 20px;"></div>
			<el-row :gutter="0">
				<el-col :span="24">
					<el-form label-width="130px" class="allForm">
						<el-form-item :label="typeName" :required="true">
							<el-input v-model="mentalTypeName" class="formInput" placeholder="请输入" />
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="24">
					<el-form label-width="130px" class="allForm">
						<el-form-item :label="typeText">
							<div class="pb-20" v-for="(item,index) in textList" :key="index">
								<el-row>
									<el-col :span="3">
										<el-input type="number" v-model="item.minScore" class="formInput" placeholder="0" />
									</el-col>
									<el-col :span="2">
										<div class="text-center">━</div>
									</el-col>
									<el-col :span="3">
										<el-input type="number" v-model="item.maxScore" class="formInput" placeholder="0" />
									</el-col>
									<el-col :span="1">
										<div class="text-center" style="color: #FFFFFF;">-</div>
									</el-col>
									<el-col :span="15">
										<el-input v-model="item.feedbackResults" class="formInput" placeholder="请输入反馈结果" />
									</el-col>
								</el-row>
								<el-row style="padding-top: 10px;" v-for="(items,indexs) in item.scoreFeedbackSchemeDtos" :key="indexs">
									<el-col :span="24">
										<el-input type="textarea" show-word-limit v-model="items.feedbackScheme" class="formInput" placeholder="请输入反馈文案" />
									</el-col>
								</el-row>
								<el-row>
<!--									<i class="el-icon-plus" @click="addFeedbackSchemes(item)"></i>-->
                                    <el-button style="margin-top: 20px;"  @click="addFeedbackSchemes(item)" icon="el-icon-plus" type="primary">添加反馈文案输入框</el-button>
								</el-row>
							</div>
							<el-button type="info" icon="el-icon-plus" @click="addList"></el-button>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col v-if="info.addOr === 'add' && falsehood == 0" :span="24">
					<el-form label-width="130px" class="allForm">
						<el-form-item label="配置测谎题数：" :required="true">
							<el-input type="number" v-model="falsehoodNum" class="formInput" placeholder="请输入" />
						</el-form-item>
					</el-form>
				</el-col>
				<el-col v-if="info.addOr === 'add'" :span="24">
					<el-form label-width="130px" class="allForm">
						<el-form-item label="是否为测谎题：" :required="true">
							<el-select v-model="falsehood" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col v-if="info.addOr === 'upd' && !row.parentId && falsehood == 0" :span="24">
					<el-form label-width="130px" class="allForm">
						<el-form-item label="配置测谎题数：" :required="true">
							<el-input type="number" v-model="falsehoodNum" class="formInput" placeholder="请输入" />
						</el-form-item>
					</el-form>
				</el-col>
				<el-col v-if="info.addOr === 'upd' && !row.parentId" :span="24">
					<el-form label-width="130px" class="allForm">
						<el-form-item label="是否为测谎题：" :required="true">
							<el-select v-model="falsehood" disabled placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="24" v-if="falsehood == 0 || (info.addOr === 'upd' && row.parentId)">
					<el-form label-width="130px" class="allForm">
						<el-form-item label="考试时长：" :required="true">
							<el-time-picker v-model="testTime" placeholder="请选择时间" @change="changeTime"></el-time-picker>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<div slot="footer" class="dialog-footer" style="text-align: center;">
				<el-button type="primary" @click="commit">提交</el-button>
				<el-button type="info" @click="close">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				typeName: '量表类型：',
				typeText: '量表信息：',
				show: this.dialog,
				falsehood: 0,
				falsehoodNum: '',
				testTime: '',
				timeLength: '',
				mentalTypeName: '',
				textList: [{
					minScore: '',
					maxScore: '',
					feedbackResults: '',
					scoreFeedbackSchemeDtos: [{
						feedbackScheme: ''
					}, {
						feedbackScheme: ''
					}],
				}],
				userShowList: false,
				options: [{
					value: 1,
					label: '是'
				}, {
					value: 0,
					label: '否'
				}],
			}
		},
		created() {},
		mounted() {
			this.upd()
		},
		methods: {
			upd() {
				if (this.info.addOr === 'upd') {
					this.textList = this.row.mentalAssessmentTypeScoreVos;
					this.mentalTypeName = this.row.mentalTypeName;
					this.timeLength = this.row.testTime;
					this.falsehoodNum = this.row.randomTopicNum;
					this.falsehood = this.row.mentalType;
					if (this.row.parentId) {
						this.info.title = '修改维度';
						this.typeName = '维度类型：';
						this.typeText = '维度信息：';
					}
					this.getTime()
				}
				if (this.info.addOr === 'addSons') {
					this.typeName = '维度类型：';
					this.typeText = '维度信息：';
				}
			},
			// 提交添加
			commit() {
				if (this.info.addOr === 'add') {
					this.$axiosReq('/course/server/escort/web/mentalAssessmentType', {
						mentalAssessmentTypeScoreDtos: this.textList,
						mentalTypeName: this.mentalTypeName,
						testTime: this.timeLength,
						mentalType: this.falsehood,
						randomTopicNum: this.falsehoodNum,
					}, null, 'post').then(res => {
						this.close()
						this.$emit('get-list')
						this.$message.success(res.msg)
					})
				} else if (this.info.addOr === 'addSons') {
					this.$axiosReq('/course/server/escort/web/mentalAssessmentDimension', {
						mentalAssessmentTypeScoreDtos: this.textList,
						mentalTypeName: this.mentalTypeName,
						parentId: this.row.id,
						testTime: this.timeLength,
						mentalType: this.falsehood,
						randomTopicNum: this.falsehoodNum,
					}, null, 'post').then(res => {
						this.close()
						this.$emit('get-list')
						this.$message.success(res.msg)
					})
				} else {
					if (this.row.parentId) {
						this.$axiosReq('/course/server/escort/web/mentalAssessmentDimension/' + this.row.id, {
							mentalAssessmentTypeScoreDtos: this.textList,
							mentalTypeName: this.mentalTypeName,
							parentId: this.row.parentId,
							testTime: this.timeLength,
						}, null, 'put').then(res => {
							this.close()
							this.$emit('get-list')
							this.$message.success(res.msg)
						})
					} else {
						this.$axiosReq('/course/server/escort/web/mentalAssessmentType/' + this.row.id, {
							mentalAssessmentTypeScoreDtos: this.textList,
							mentalTypeName: this.mentalTypeName,
							testTime: this.timeLength,
							mentalType: this.falsehood,
							randomTopicNum: this.falsehoodNum,
						}, null, 'put').then(res => {
							this.close()
							this.$emit('get-list')
							this.$message.success(res.msg)
						})
					}
				}
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			},
			addList() {
				this.textList.push({
					minScore: '',
					maxScore: '',
					feedbackResults: '',
					scoreFeedbackSchemeDtos: [{
						feedbackScheme: ''
					}, {
						feedbackScheme: ''
					}],
				});
			},
			addFeedbackSchemes(item) {
				item.scoreFeedbackSchemeDtos.push({
					feedbackScheme: ''
				});
			},
			changeTime(v) {
				if (v) {
					this.timeLength = v.getHours() * 3600 + v.getMinutes() * 60 + v.getSeconds()
				} else{
					this.timeLength = '';
				}
			},
			getTime() {
				var date = new Date();
				var hour = Math.floor(this.timeLength / 3600);
				var minute = Math.floor((this.timeLength - hour * 3600) / 60);
				var second = this.timeLength - hour * 3600 - minute * 60;
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var strDate = date.getDate();
				if (month >= 1 && month <= 9) {
					month = "0" + month;
				}
				if (strDate >= 0 && strDate <= 9) {
					strDate = "0" + strDate;
				}
				this.testTime = new Date(year, month, strDate, hour, minute, second)
			}
		}
	}
</script>

<style scoped>
	.input-with-select {
		width: 280px;
	}

	.pb-20 {
		padding-bottom: 20px;
	}
</style>
