var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-container"},[(_vm.dialog.refresh)?_c('Operate',{attrs:{"dialog":_vm.dialog.show,"row":_vm.dialog.row,"info":_vm.dialog.info},on:{"update:dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"get-list":_vm.getList,"refresh":_vm.refreshDialog}}):_vm._e(),_c('el-card',{staticStyle:{"margin-top":"20px"}},[_c('el-row',[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',[(
                                            _vm.$hasBtn(
                                                'sys:permissions:config:evaluation:add'
                                            )
                                        )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.openDialog({title:'添加量表',addOr:'add'})}}},[_vm._v("添加量表")]):_vm._e(),(
                                            _vm.$hasBtn(
                                                'sys:permissions:config:evaluation:batch:delete'
                                            )
                                        )?_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.removeAll({del:'batch'})}}},[_vm._v("批量删除")]):_vm._e()],1),_c('el-col',{attrs:{"span":1}},[_c('vxe-button',{on:{"click":_vm.getList}},[_vm._v("刷新")])],1)],1)]},proxy:true}])}),_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":"660","row-id":"company_id","sort-config":{remote: true},"tree-config":{children: 'mentalAssessmentDimensionVos'},"filter-config":{remote: true},"checkbox-config":{reserve: true},"data":_vm.table.list,"loading":_vm.loading}},[_c('vxe-table-column',{attrs:{"type":"checkbox","width":"100","fixed":"left"}}),_c('vxe-table-column',{attrs:{"type":"seq","width":"100"}}),_c('vxe-table-column',{attrs:{"field":"mentalTypeName","title":"题目类型","tree-node":""}}),_c('vxe-table-column',{attrs:{"field":"statu","title":"得分范围","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return _vm._l((row.mentalAssessmentTypeScoreVos),function(item,index){return _c('div',{key:index},[(index != (row.mentalAssessmentTypeScoreVos.length - 1))?_c('div',{staticStyle:{"border-bottom":"1px solid #DCDCDC"}},[_vm._v(_vm._s(item.minScore)+"-"+_vm._s(item.maxScore))]):_vm._e(),(index == (row.mentalAssessmentTypeScoreVos.length - 1))?_c('div',[_vm._v(_vm._s(item.minScore)+"-"+_vm._s(item.maxScore))]):_vm._e()])})}}])}),_c('vxe-table-column',{attrs:{"field":"statu","title":"反馈结果","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return _vm._l((row.mentalAssessmentTypeScoreVos),function(item,index){return _c('div',{key:index},[(index != (row.mentalAssessmentTypeScoreVos.length - 1))?_c('div',{staticStyle:{"border-bottom":"1px solid #DCDCDC"}},[_vm._v(_vm._s(item.feedbackResults))]):_vm._e(),(index == (row.mentalAssessmentTypeScoreVos.length - 1))?_c('div',[_vm._v(_vm._s(item.feedbackResults))]):_vm._e()])})}}])}),_c('vxe-table-column',{attrs:{"field":"","title":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
                                        var rowIndex = ref.rowIndex;
return [_c('el-dropdown',{attrs:{"size":"small","type":"primary"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v(" 操作"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                                            _vm.$hasBtn(
                                                'sys:permissions:config:evaluation:edit'
                                            )
                                        )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-edit"},nativeOn:{"click":function($event){return _vm.openDialog({title:'修改量表',addOr:'upd'},row)}}},[_vm._v("修改")]):_vm._e(),(!row.parentId)?_c('el-dropdown-item',{attrs:{"icon":"el-icon-edit"},nativeOn:{"click":function($event){return _vm.openDialog({title:'添加维度',addOr:'addSons'},row)}}},[_vm._v("添加")]):_vm._e(),(
                                            _vm.$hasBtn(
                                                'sys:permissions:config:evaluation:delete'
                                            )
                                        )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"},nativeOn:{"click":function($event){return _vm.remove({del:'single'},row)}}},[_vm._v("删除")]):_vm._e()],1)],1)]}}])})],1),_c('div',{staticStyle:{"height":"20px"}}),_c('el-pagination',{attrs:{"background":"","current-page":_vm.table.currentPage,"page-sizes":[5, 10, 15, 20, 50, 100],"page-size":_vm.table.pageSize,"layout":"total, ->, sizes, prev, pager, next, jumper","total":_vm.table.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }