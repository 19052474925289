<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<el-card style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="space-between">
							<el-col>
								<el-button v-if="
                                            $hasBtn(
                                                'sys:permissions:config:evaluation:add'
                                            )
                                        "
								 type="primary" size="mini" @click="openDialog({title:'添加量表',addOr:'add'})">添加量表</el-button>
								<!-- <el-button type="warning" size="mini" @click="openExport({title:'批量导入',addOr:'batch'})">批量导入</el-button> -->
								<el-button v-if="
                                            $hasBtn(
                                                'sys:permissions:config:evaluation:batch:delete'
                                            )
                                        "
								 type="danger" size="mini" @click="removeAll({del:'batch'})">批量删除</el-button>
							</el-col>
							<el-col :span="1">
								<vxe-button @click="getList">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="660" row-id="company_id" :sort-config="{remote: true}"
				 :tree-config="{children: 'mentalAssessmentDimensionVos'}" :filter-config="{remote: true}" :checkbox-config="{reserve: true}"
				 :data="table.list" :loading="loading">
					<vxe-table-column type="checkbox" width="100" fixed="left" />
					<vxe-table-column type="seq" width="100" />
					<vxe-table-column field="mentalTypeName" title="题目类型" tree-node />
					<vxe-table-column field="statu" title="得分范围" width="300">
						<template v-slot="{row}">
							<div v-for="(item,index) in row.mentalAssessmentTypeScoreVos" :key="index">
								<div v-if="index != (row.mentalAssessmentTypeScoreVos.length - 1)" style="border-bottom: 1px solid #DCDCDC;">{{item.minScore}}-{{item.maxScore}}</div>
								<div v-if="index == (row.mentalAssessmentTypeScoreVos.length - 1)">{{item.minScore}}-{{item.maxScore}}</div>
							</div>
						</template>
					</vxe-table-column>
					<vxe-table-column field="statu" title="反馈结果" width="300">
						<template v-slot="{row}">
							<div v-for="(item,index) in row.mentalAssessmentTypeScoreVos" :key="index">
								<div v-if="index != (row.mentalAssessmentTypeScoreVos.length - 1)" style="border-bottom: 1px solid #DCDCDC;">{{item.feedbackResults}}</div>
								<div v-if="index == (row.mentalAssessmentTypeScoreVos.length - 1)">{{item.feedbackResults}}</div>
							</div>
						</template>
					</vxe-table-column>
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="
                                            $hasBtn(
                                                'sys:permissions:config:evaluation:edit'
                                            )
                                        "
									 icon="el-icon-edit" @click.native="openDialog({title:'修改量表',addOr:'upd'},row)">修改</el-dropdown-item>
									<el-dropdown-item v-if="!row.parentId" icon="el-icon-edit" @click.native="openDialog({title:'添加维度',addOr:'addSons'},row)">添加</el-dropdown-item>
									<el-dropdown-item v-if="
                                            $hasBtn(
                                                'sys:permissions:config:evaluation:delete'
                                            )
                                        "
									 icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import Operate from './Operate'

	export default {
		name: 'Index',
		components: {
			Operate,
		},
		data() {
			return {
				company: [],
				companyId: '',
				loading: false,
				condition: {
					carNum: '',
					date: '',
					search: '',
					line: '',
					sDate: '',
					eDate: ''
				},
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					refresh2: false, // 每次刷新DOM
					refresh3: false, // 每次刷新DOM
					refreshUser: false, // 每次刷新DOM
					refreshIns: false, // 每次刷新DOM
					show: false,
					show2: false,
					show3: false,
					showUser: false,
					showIns: false
				}
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			rowStyle,
			headerStyle,
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true
				this.dialog.show = true
				this.dialog.info = info
				this.dialog.row = row
			},
			openUser(info, row) {
				this.dialog.refreshUser = true
				this.dialog.showUser = true
				this.dialog.info = info
				this.dialog.row = row
			},
			// 打开批量导入
			openExport() {
				this.dialog.refresh2 = true
				this.dialog.show2 = true
			},
			openView(info, row) {
				this.dialog.refresh3 = true
				this.dialog.show3 = true
				this.dialog.info = info
				this.dialog.row = row
			},
			openIns(info, row) {
				this.dialog.refreshIns = true
				this.dialog.showIns = true
				this.dialog.info = info
				this.dialog.row = row
			},
			changeCondition() {
				this.table.currentPage = 1
				this.getList()
			},
			changeCondition() {
				this.table.currentPage = 1
				this.getList()
				this.$refs.table.clearCheckboxRow()
			},
			getCompany() {
				this.$axiosReq(company, null, {
					currentPage: 1,
					pageSize: 10000,
				}, 'get').then(res => {
					if (res.data.content && res.data.content.length > 0) {
						this.company = res.data.content;
					}
				})
			},
			getList() {
				this.loading = true
				this.$axiosReq('/course/server/escort/web/mentalAssessmentType', null, {
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(res.data.totalElements)
				}).finally(e => {
					this.loading = false
				})
			},
			removeAll() {
				const id = []
				const arr = this.$refs.table.getCheckboxRecords()
				for (const i of arr) {
					id.push(i.id)
				}
				if (!id || id.length < 1) {
					this.$message.info('请选择需要删除的对象！')
					return false
				}
				this.$confirm('确认删除吗！！！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axiosReq('/course/server/escort/web/mentalAssessmentType', id, null, 'delete').then(res => {
						this.$message.success(res.msg)
						this.getList()
					})
				}).catch(() => {
					this.$message.info('取消了删除！！！')
				})
			},
			remove(info, row) {
				this.$confirm('确认删除吗！！！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axiosReq('/course/server/escort/web/mentalAssessmentType/' + row.id, null, null, 'delete').then(res => {
						this.$message.success(res.msg)
						this.getList()
					})
				}).catch(() => {
					this.$message.info('取消了删除！！！')
				})
			},
			changeStatus(v, id) {
				changeCarStatus({
					carId: id,
					status: v
				}, null, 'put').then(res => {
					this.$message.success(res.data.msg)
					this.getList()
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			refreshDialog() {
				this.dialog.refresh = false
				this.dialog.refresh2 = false
				this.dialog.refresh3 = false
				this.dialog.refreshUser = false
				this.dialog.refreshIns = false
				this.getList()
			},
			selectAll(e) {
				console.log(JSON.stringify(e))
			},
			selectBox() {
				console.log(JSON.stringify(event))
			},
		}
	}
</script>

<style scoped>

</style>
